

// @ts-nocheck

import Vue from 'vue';

import { mapState, mapActions } from 'vuex';

import { dreamsActions } from '@/store/modules/dreams/names';
import { ComposedState } from '@/types';

import moment from 'moment';

export default Vue.extend({
    name: 'Dreams',

    data() {
        return {
            sort: {
                sortBys: [],
                sortDesc: [],
            },
            sortObj: {},
            filters: {
                filterPopular: null,
                filterCategoryValue: null,
                filterStatusDream: null,
            },
            search: '',
            options: {},
            loading: false,
            footerProps: {
                'items-per-page-options': [2, 5, 10, 15],
            },
            headers: [
                {
                    text: 'Title',
                    align: 'left',
                    value: 'title',
                },
                { text: 'Id', value: 'dream_id' },
                { text: 'User', value: 'name' },
                { text: 'Collected', value: 'collected' },
                { text: 'Created', value: 'created_at' },
                { text: 'Closed', value: 'closed_at' },
                { text: 'Category', value: 'category_id' },
                { text: 'Views', value: 'views' },
                { text: 'Likes', value: 'likes' },
            ],
        };
    },

    computed: {
        ...mapState<ComposedState>({
            dreams: (state: ComposedState) => state.dreams.dreams,
            categoryList: (state: ComposedState) => state.dreams.categoryList,
            dreamsTotal: (state: ComposedState) => state.dreams.dreamsTotal,
        }),

        count(): number {
            return this.dreamsTotal;
        },
        
        sortOptions() {
            return {
                page: this.options.page,
                size: this.options.itemsPerPage,
                is_vip: this.filters.filterPopular,
                categories: this.filters.filterCategoryValue,
                active: this.filters.filterStatusDream,
                ...this.sortObj,
                search: this.search ? this.search : null,
            };
        },
    },

    watch: {
        search: {
            handler() {
                this.options.page = 1;
                this.getDreamWithFilters();
            },
            deep: true,
        },
        'filters.filterCategoryValue': {
            handler() {
                this.getDreamWithFilters();
            },
            deep: true,
        },
    },

    methods: {
        ...mapActions({
            getDreams: dreamsActions.getDreams,
        }),

        onChangeHandler(status, field) {
            this.filters = { ...this.filters, [field]: status || null };
            this.getDreamWithFilters();
        },

        getDreamWithFilters() {
            this.getDreams({ ...this.sortOptions });
        },

        calcStatusDream(item) {
            const isWithPaidImrixSubscribe
                = new Date() < moment.utc(item.user.paid_till).local().toDate();
            const isWithTrialImrixSubscribe
                = new Date() < moment.utc(item.user.trial_till).local().toDate();
            return ((isWithPaidImrixSubscribe || isWithTrialImrixSubscribe) && item.status === 60)
                ? 'background-color: rgba(44, 121, 0, 0.5)'
                : 'background-color: rgba(122, 0, 0, 0.5)';
        },

        async changeSort() {
            await this.$nextTick();

            const arrAscDesc = this.sort.sortDesc.map((item) => {
                if (item === false) {
                    return 'asc';
                }
                return 'desc';
            });

            this.sortObj = this.sort.sortBys.reduce(
                (acc, n, i) => ({ ...acc, [n]: arrAscDesc[i] }),
                {},
            );

            this.getDreamWithFilters();
        },

        onFilterChange() {
            this.options.page = 1;

            this.getDreamWithFilters();
        },
    },
});
